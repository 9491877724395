export interface IAuthInfo {
  token: string;
  operator_id: number;
  operator_name: string;
}

export interface IAuthInfoRecover {
  _token: string;
  _operator_id: number;
  _operator_name: string;
}

export function everyTenInprint(au: string): string {
  if (au && au.length >= 1) {
    let result = '';
    for (var i = 0; i < au.length; i += 10) result += au.charAt(i);
    return result;
  }
  return au;
}

export class AuthInfo implements IAuthInfo {
  private _token: string;
  private _operator_id: number;
  private _operator_name: string;

  constructor(token: string, operator_id: number, operator_name: string) {
    this._token = token;
    this._operator_id = operator_id;
    this._operator_name = operator_name;
  }

  get token(): string {
    return this._token;
  }

  get operator_id(): number {
    return this._operator_id;
  }

  get operator_name(): string {
    return this._operator_name;
  }

  set token(value) {
    this._token = value;
  }

  set operator_id(value) {
    this._operator_id = value;
  }

  set operator_name(value) {
    this._operator_name = value;
  }
}

export const LOGOUT_STATUS = 'Online';

export interface SomewhereOnlineMessage {
  id: number;
  status: string;
}
