import axios from 'axios';
// import * as axios from "axios";
import { PATH_HOME_LOGIN } from 'constants/paths';
import { AuthInfo, IAuthInfo } from './IAuthInfo';
import { IS_VALID_TOKEN_URL, LOGIN_CS_URL, LOGIN_URL } from './Chat';

export class LoginProvider {
  constructor() {}

  async login4Token(login: string, password: string, ready: any) {
    const loginParam = {
      login: '' + login,
      password: '' + password,
    };
    const apiPath = LOGIN_URL;
    axios
      .post(apiPath, loginParam)
      .then((response) => {
        const authData: IAuthInfo = response.data;
        ready(new AuthInfo(authData.token, authData.operator_id, authData.operator_name));
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

export class StealCookies {
  constructor() {}

  async autologin4Token(ready: any) {
    const config = {
      headers: {
        'Access-Control-Allow-Credentials': 'true',
      },
      withCredentials: true,
    };
    const apiPath = LOGIN_CS_URL;
    axios
      .post(apiPath, {}, config)
      .then((response) => {
        const authData: IAuthInfo = response.data;

        if (('' + authData.token).length < 10) document.location = PATH_HOME_LOGIN;
        else ready(new AuthInfo(authData.token, authData.operator_id, authData.operator_name));
      })
      .catch((err) => {
        console.error(err);
        document.location = PATH_HOME_LOGIN;
      });
  }
}

export class CheckTokenWithNoContactsProvider {
  constructor() {}

  async checkToken(token: string, ready: any) {
    axios
      .post(IS_VALID_TOKEN_URL, {
        token: '' + token,
      })
      .then((response) => {
        const rd = response.data;
        if (ready) ready(rd);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
