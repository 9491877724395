// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_topMenu__nY6uG {
  width: 100%;
  height: 64px;
  background-color: #5f8fdf;
  padding: 0px 16px;
}
.style_topMenu__nY6uG .style_sideMenuNav__JkclR {
  padding: 0;
  height: 100%;
}

.style_logo__iayXH {
  color: #ffffff !important;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/UpsidePanel/style.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,yBCEa;EDDb,iBAAA;AADF;AAGE;EACE,UAAA;EACA,YAAA;AADJ;;AAKA;EACE,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,SAAA;AAFF","sourcesContent":["@import '../../variables.scss';\n\n.topMenu {\n  width: 100%;\n  height: 64px;\n  background-color: $active-color;\n  padding: 0px 16px;\n\n  .sideMenuNav {\n    padding: 0;\n    height: 100%;\n  }\n}\n\n.logo {\n  color: $white !important;\n  font-size: 24px;\n  line-height: 32px;\n  font-weight: 400;\n  margin: 0;\n}\n","$white: #ffffff;\n$black: #000000;\n\n$gray-background: #f5f5f7;\n$gray-border: #dce1e6;\n$gray-box-border: #e9e9eb;\n$gray-active: #eaedef;\n$active-color: #5f8fdf;\n// $active-color: #00ff95;\n$sender-message-color: #cce4ff;\n\n$text-color-800: #4a596a;\n$text-color-700: #626d7a;\n$text-color-600: #909399;\n\n// $header-color: #add8e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topMenu": `style_topMenu__nY6uG`,
	"sideMenuNav": `style_sideMenuNav__JkclR`,
	"logo": `style_logo__iayXH`
};
export default ___CSS_LOADER_EXPORT___;
