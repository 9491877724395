// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_chatLoginScreen__exHMr {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f7;
}
.style_chatLoginScreen__exHMr .style_chatLogin__i2CWY {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.08);
  gap: 24px;
}
.style_chatLoginScreen__exHMr .style_chatLogin__i2CWY h3 {
  margin: 0;
  color: #5f8fdf;
}
.style_chatLoginScreen__exHMr .style_chatLogin__i2CWY .style_inputs__-NpRq {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.style_chatLoginScreen__exHMr .style_chatLogin__i2CWY .style_inputs__-NpRq input {
  width: 100%;
}
.style_chatLoginScreen__exHMr .style_chatLogin__i2CWY button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Login/style.module.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBCLgB;ADIlB;AAGE;EACE,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,oCAAA;EACA,mBAAA;EACA,sBAAA;EACA,8CAAA;EACA,SAAA;AADJ;AAGI;EACE,SAAA;EACA,cChBS;ADef;AAII;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;AAFN;AAIM;EACE,WAAA;AAFR;AAMI;EACE,WAAA;AAJN","sourcesContent":["@import '../../variables.scss';\n\n.chatLoginScreen {\n  display: flex;\n  width: 100%;\n  height: 100vh;\n  align-items: center;\n  justify-content: center;\n  background-color: $gray-background;\n\n  .chatLogin {\n    width: 360px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 2.5rem 1.5rem 1.5rem 1.5rem;\n    border-radius: 1rem;\n    background-color: #fff;\n    box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.08);\n    gap: 24px;\n\n    h3 {\n      margin: 0;\n      color: $active-color;\n    }\n\n    .inputs {\n      display: flex;\n      flex-direction: column;\n      gap: 8px;\n      width: 100%;\n\n      input {\n        width: 100%;\n      }\n    }\n\n    button {\n      width: 100%;\n    }\n  }\n}\n","$white: #ffffff;\n$black: #000000;\n\n$gray-background: #f5f5f7;\n$gray-border: #dce1e6;\n$gray-box-border: #e9e9eb;\n$gray-active: #eaedef;\n$active-color: #5f8fdf;\n// $active-color: #00ff95;\n$sender-message-color: #cce4ff;\n\n$text-color-800: #4a596a;\n$text-color-700: #626d7a;\n$text-color-600: #909399;\n\n// $header-color: #add8e6;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatLoginScreen": `style_chatLoginScreen__exHMr`,
	"chatLogin": `style_chatLogin__i2CWY`,
	"inputs": `style_inputs__-NpRq`
};
export default ___CSS_LOADER_EXPORT___;
