import React, { FC, memo } from 'react';
import { Navbar, NavbarBrand } from 'reactstrap';
import classes from './style.module.scss';
import { AUTH_TAG, cookieSet } from 'types/Chat';
import { PATH_HOME, PATH_HOME_LOGIN } from 'constants/paths';

interface IUpsidePanelProps {
  withLogout: boolean;
  setLogouted: () => void;
}

const UpsidePanel: FC<IUpsidePanelProps> = (props) => {
  const { withLogout, setLogouted } = props;

  function onLogoutClick() {
    setLogouted();
    tokenClear();
    document.location = PATH_HOME_LOGIN;
  }

  function tokenClear() {
    cookieSet(AUTH_TAG, '');
  }

  return (
    <div className={classes.topMenu}>
      <Navbar className={classes.sideMenuNav} role="tablist">
        <a className={classes.logo} href={PATH_HOME}>
          Payment Guru
        </a>

        {withLogout && (
          <button onClick={onLogoutClick} className="button default">
            Logout
          </button>
        )}
      </Navbar>
    </div>
  );
};

export default memo(UpsidePanel);
