export interface IPlayerBalance {
  firstDeposit: number | null;
  lastDeposit: number | null;
  totalDeposits: number | null;
  totalWithdrawals: number | null;
}

export function createEmptyPlayerBalance(): IPlayerBalance {
  return {
    firstDeposit: 0,
    lastDeposit: 0,
    totalDeposits: 0,
    totalWithdrawals: 0,
  };
}

export enum BalanceMode {
  MODE_CREDIT = 1,
  MODE_BALANCE = 2,
}

export enum BalanceModeLabel {
  CREDIT = 'Credit',
  BALANCE = 'Balance',
}

export enum ChatRecoveryMode {
  CRM_RELOGIN = 1,
  CRM_RECONNECT = 2,
}

export interface IBalanceChanges {
  contact_id: number;
  hall_id: number;
  payId: string;
  message: string;
  type: string;
  command: string;
  argument: number;
}

export interface IBalanceModeChanges extends IBalanceChanges {}

export interface IWeeklyResetChanges extends IBalanceChanges {}
