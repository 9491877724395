// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_chat__rscm7 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.style_chatContainers__-6xKd {
  display: flex;
  width: 100%;
  height: calc(100vh - 64px) !important;
  height: min-content;
  align-content: center;
  text-align: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,qCAAA;EACA,mBAAA;EACA,qBAAA;EACA,kBAAA;EACA,mBAAA;AACF","sourcesContent":[".chat {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100vh;\n  max-height: 100vh;\n  overflow: hidden;\n}\n\n.chatContainers {\n  display: flex;\n  width: 100%;\n  height: calc(100vh - 64px) !important;\n  height: min-content;\n  align-content: center;\n  text-align: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat": `style_chat__rscm7`,
	"chatContainers": `style_chatContainers__-6xKd`
};
export default ___CSS_LOADER_EXPORT___;
