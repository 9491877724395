import React, { FC, FormEvent, memo, useState } from 'react';
import { Button, Input, Form } from 'reactstrap';
import classes from './style.module.scss';
import { AUTH_TAG, PASSTHROUGH_AUTH_TAG, cookieSet, cookieGet } from 'types/Chat';
import { ENV } from '../../.env';
import { IAuthInfo } from 'types/IAuthInfo';
import { LoginProvider, StealCookies } from 'types/Login';
import Cookies from 'js-cookie';
import { fetchCookieTest } from 'types';
import { PATH_HOME } from 'constants/paths';
import { AuthCookieValue } from 'types/soket';
import { useInput } from 'types/React';

const env = ENV || 'dev';
const config = require('../../config/config.json')[env];

interface IHomeLoginProps {
  getLogouted: (au: AuthCookieValue) => boolean;
  clearLogouted: () => boolean;
}

const HomeLogin: FC<IHomeLoginProps> = (props) => {
  const { getLogouted, clearLogouted } = props;

  const login = useInput('' + config.LOGIN);
  const password = useInput('' + config.PASSWORD);
  const [authInfo, setAuthInfo] = useState<IAuthInfo>();

  /*
  console.log('DC ' + document.cookie);
  fetchCookieTest();
*/

  function tokenReadyA(authInfo: IAuthInfo) {
    setAuthInfo(authInfo);
    cookieSet(AUTH_TAG, JSON.stringify(authInfo));
    document.location = PATH_HOME;
  }

  function tokenReady(authInfo: IAuthInfo) {
    setAuthInfo(authInfo);
    cookieSet(AUTH_TAG, JSON.stringify(authInfo));
    document.location = PATH_HOME;
  }

  function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
  }

  const au = getCookie(PASSTHROUGH_AUTH_TAG);
  console.log('au', au);

  function autologinRepeatCheck(au: AuthCookieValue) {
    console.log('AU ' + au + ' lo ' + getLogouted(au));
    console.log('document.referrer: ' + document.referrer);
    if (getLogouted(au) && document.referrer != 'https://preprod.paymentguru.site/') return;

    if (au !== null && au !== undefined && au.length > 1) {
      setTimeout(() => {
        new StealCookies().autologin4Token(tokenReadyA);
      }, 1500 / 10);
    } else {
      setTimeout(() => {
        autologinRepeatCheck(au);
      }, 4999);
    }
  }

  autologinRepeatCheck(au);

  const onLoginProc = (e: FormEvent<HTMLFormElement>, loginData: string, passwordData: string) => {
    clearLogouted();
    e.preventDefault();
    const lp = new LoginProvider();
    lp.login4Token(loginData, passwordData, tokenReady);
  };

  return (
    <Form onSubmit={(e) => onLoginProc(e, login.value, password.value)}>
      <div className={classes.chatLoginScreen}>
        <div className={classes.chatLogin}>
          <h3>Welcome</h3>
          <div className={classes.inputs}>
            <Input
              type="text"
              {...login}
              className="login"
              placeholder={"User's name"}
              style={{ resize: 'none' }}
              maxLength={128}
              autocomplete="off"
            />
            <Input
              type="password"
              {...password}
              className="password"
              placeholder={"User's password"}
              style={{ resize: 'none' }}
              maxLength={128}
              autocomplete="new-password"
            />
          </div>
          <button type="submit" className="button primary">
            Login
          </button>
        </div>
      </div>
    </Form>
  );
};

export default memo(HomeLogin);
