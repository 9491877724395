import { useState } from "react";

export function toogleProc(toogleTarget: string, tooglingLatch: string, stateOfToogle: boolean) {
  const findings = document.getElementsByClassName('' + toogleTarget);
  if (findings) {
    if (stateOfToogle) findings[0].classList.remove('' + tooglingLatch);
    else findings[0].classList.add('' + tooglingLatch);
  }
}

export function toogleMultyProc(
  toogleTarget: string,
  tooglingLatch: string,
  stateOfToogle: boolean
) {
  const findings = document.getElementsByClassName('' + toogleTarget);
  if (findings) {
    if (stateOfToogle)
      for (let i = 0; i < findings.length; i++) findings[i].classList.remove('' + tooglingLatch);
    else for (let i = 0; i < findings.length; i++) findings[i].classList.add('' + tooglingLatch);
  }
}

export function activateTab(lastTab: string, activationTab: string, activator: string) {
  const tabLast = document.getElementsByClassName('' + lastTab);
  if (tabLast) tabLast[0].classList.remove('' + activator);

  const tabActivation = document.getElementsByClassName('' + activationTab);
  if (tabActivation) tabActivation[0].classList.add('' + activator);
}

export function toogleAdd(toogleTarget: string, activator: string) {
  const findings = document.getElementsByClassName(toogleTarget);
  if (findings) findings[0].classList.add(activator);
}

export function toogleDel(toogleTarget: string, activator: string) {
  const findings = document.getElementsByClassName(toogleTarget);
  if (findings) findings[0].classList.remove(activator);
}

export function search(toogleTarget: string) {
  const findings = document.getElementsByClassName(toogleTarget);
  return findings;
}

export function useInput(param: string) {
  const [value, setValue] = useState<string>('' + param);

  const onChange = (e: any) => {
    setValue(e.target.value);
  }

  return {
    value, onChange
  };
}