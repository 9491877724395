import React, { FC, memo, Suspense } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import UpsidePanel from 'components/UpsidePanel';
import classes from './style.module.scss';
import { PATH_HOME } from 'constants/paths';
import { RouteProps, Route, Routes } from 'react-router-dom';

interface ILayoutProps {
  setLogouted: () => void;
}

const Layout: FC<ILayoutProps> = (props) => {
  const { setLogouted } = props;

  const params = useParams();
  const dispatch = useDispatch();
  const Guruchat = React.lazy(() => import('pages'));
  const ROUTES: RouteProps[] = [
    { path: PATH_HOME, element: <Guruchat setLogouted={setLogouted} /> },
  ];

  return (
    <div className={classes.chat}>
      <UpsidePanel withLogout={true} setLogouted={setLogouted} />

      <div className={classes.chatContainers}>
        <Routes>
          {ROUTES.map(({ element, path }) => (
            <Route
              key={path}
              path={path}
              element={<Suspense fallback={<span></span>}>{element}</Suspense>}
            />
          ))}
        </Routes>
      </div>
    </div>
  );
};

export default memo(Layout);
