import { ThunkAction } from 'redux-thunk';
import { createBrowserHistory, History } from 'history';
import { createStore, applyMiddleware, Reducer, Store } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { Action } from '@reduxjs/toolkit';
import shortcutReducer from '../features/shortcut/shortcutSlice';

const createRootReducer = (history: History) =>
  combineReducers({
    router: routerReducer,
    shortcut: shortcutReducer,
  });

export const configureStore = (
  rootReducer: Reducer<any, any>,
  history: History
): Store<any, any> => {
  const middlewares = applyMiddleware(thunk, routerMiddleware(history));
  return createStore(rootReducer, middlewares);
};

export const history = createBrowserHistory();
export const rootReducer = createRootReducer(history);
export const store = configureStore(rootReducer, history);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
