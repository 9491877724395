import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import Layout from 'components/Layout';
import HomeLogin from 'components/Login';
import { PATH_HOMES, PATH_HOME_LOGIN } from 'constants/paths';
import 'assets/scss/themes_.scss';
import { BrowserRouter } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import { LOGOUT_TAG, PASSTHROUGH_AUTH_TAG } from 'types/Chat';
import { AuthCookieValue } from 'types/soket';
import { everyTenInprint } from 'types/IAuthInfo';
import './globals.scss';

class App extends Component {
  logouted = false;
  logoutedCtx = '';

  constructor(props) {
    super(props);

    const { cookies } = props;
    this.state = {
      authid: Cookies.get('' + PASSTHROUGH_AUTH_TAG) || '',
      setLogouted: () => {
        App.logouted = true;
        App.authid = cookies.get(PASSTHROUGH_AUTH_TAG);
        App.logoutedCtx = everyTenInprint(App.authid);
        cookies.set(LOGOUT_TAG, App.logoutedCtx);
        console.log('set log <' + App.logoutedCtx + '><' + App.authid + '>');
        // cookies.set(LOGOUT_TAG, '' + new Date().toLocaleString());
        // cookieSet(LOGOUT_TAG,'' + new Date().toLocaleString());
      },
      clearLogouted: () => {
        App.logouted = false;
        cookies.set(LOGOUT_TAG, '');
        // cookieSet(LOGOUT_TAG,'' + new Date().toLocaleString());
      },
      getLogouted: (au) => {
        App.logoutedCtx = cookies.get(LOGOUT_TAG);
        console.log('get log <' + App.logoutedCtx + '><' + au + '>');
        App.logouted = App.logoutedCtx === everyTenInprint(au);
        return App.logouted === true;
        // const cur = cookies.get(LOGOUT_TAG);
        // return cur !== null && cur !== undefined && ('' + cur).length > 0;
      },
    };
  }

  render() {
    const { authid, setLogouted, getLogouted, clearLogouted } = this.state;

    console.log('au <' + authid + '> lo ' + getLogouted(authid));

    return (
      <BrowserRouter>
        <Routes>
          <Route path={PATH_HOMES} element={<Layout setLogouted={setLogouted} />} />
          <Route
            path={PATH_HOME_LOGIN}
            element={<HomeLogin getLogouted={getLogouted} clearLogouted={clearLogouted} />}
          />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default withCookies(App);
