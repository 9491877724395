// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-valid.is-invalid {
  border-color: #ec205f !important;
}
.input-valid.is-invalid:focus {
  outline: 3px solid rgba(236, 32, 95, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/globals.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;AADF;AAGE;EACE,yCAAA;AADJ","sourcesContent":["@import './variables.scss';\n\n.input-valid.is-invalid {\n  border-color: #ec205f !important;\n\n  &:focus {\n    outline: 3px solid rgba(236, 32, 95, 0.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
